import * as React from 'react'
import * as Styles from './index.module.sass'
import { StaticImage } from "gatsby-plugin-image"

const IndexPage = () => {
  return (
    <>
      <div>
        <div className='columns is-centered is-vcentered'>
          <div className={Styles.mainimage}>
            <StaticImage layout={'fullWidth'} placeholder={'none'} src={'../images/main.png'} alt="Ювелирная мануфактура Касторский" />
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
